<!--
 * @Author: zhai
 * @Description: 工作经验 -编辑 老CRM-----完善客户信息现在使用的
-->
<template>
  <div class="pdt-20">
    <el-form
      ref="userForm"
      :model="userForm"
      :rules="rules"
      label-width="140px"
    >
      <!-- 主申请人工作经历 -->
      <div class="card-container">
        <el-row class="block-top">
          <!-- 左 -->
          <el-col v-loading="isLoading" :span="12">
            <el-form-item
              label="用人单位:"
              prop="company_name"
              :rules="[
                { required: true, message: '请输入用人单位', trigger: 'blur' },
              ]"
            >
              <el-autocomplete
                style="width: 163px"
                v-model="userForm.company_name"
                :fetch-suggestions="querySearchAsync"
                :trigger-on-focus="false"
                placeholder="与工作证明的盖章用人单位（全程）一致"
                @select="checkEnterprise($event.value)"
                @change="checkEnterprise"
              />
              <el-checkbox
                v-model="isFamous"
                class="tick-famous-enterprise"
                @change="triggerTick"
                >一类名企</el-checkbox
              >
              <el-checkbox
                v-model="isSecondFamous"
                class="tick-famous-enterprise"
                @change="triggerTickSec"
                >二类名企</el-checkbox
              >
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="用人单位官网:"
            >
              <el-input v-model.trim="userForm.company_website" placeholder="请输入" clearable maxlength="200" class="full-width"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="用人单位介绍:"
            >
              <el-input disabled v-model.trim="userForm.company_introduce" type="textarea" :autosize="{ minRows: 3, maxRows: 4}"  placeholder="请输入" show-word-limit clearable s maxlength="2000" class="full-width"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="用人单位性质:"
              prop="industry"
            >
              <el-select
                v-model="userForm.industry"
                class="full-width"
                placeholder="请选择"
                clearable
                disabled
              >
                <el-option
                  v-for="(item, index) in subsidiaryOrganList"
                  :key="index"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="userForm.industry === '其他（请备注）'">
            <el-form-item label="备注:" prop="business_remark" :rules="[
                {
                  required: true,
                  message: '请输入',
                  trigger: 'change',
                },
              ]">
                <el-input v-model.trim="userForm.business_remark" placeholder="请输入" clearable maxlength="200" class="full-width"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-row>
          <el-col :span="12">
            <div style="display: flex">
              <el-form-item
                label="工作地点:"
                :rules="{
                  required: true,
                  message: '请输入国家及地区',
                  trigger: 'blur',
                }"
                prop="location.country"
                style="width: 300px"
              >
                <CountrySelect
                  v-model="userForm.location.country"
                  placeholder="输入匹配国家及地区"
                  @input="handleSelect"
                  style="width: 163px"
                />
              </el-form-item>
              <el-form-item
                v-if="
                  userForm.location.country.indexOf('中国') !== -1 ||
                  userForm.location.country === ''
                "
                prop="location.area"
                :rules="{
                  required: true,
                  message: '请选择城市',
                  trigger: 'change',
                }"
                label-width="0"
              >
                <CitySelect
                  :country="userForm.location.country"
                  v-model="userForm.location.area"
                  style="width: 140px"
                />
              </el-form-item>
              <el-form-item
                v-else
                prop="location.foreign"
                :rules="{
                  required: true,
                  message: '请输入城市',
                  trigger: 'blur',
                }"
                label-width="0"
              >
                <el-input
                  v-model="userForm.location.foreign"
                  clearable
                  placeholder="请输入城市"
                  style="width: 140px"
                />
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="职位:"
              prop="position"
              :rules="[
                { required: true, message: '请输入职位', trigger: 'blur' },
              ]"
            >
              <el-input
                v-model.trim="userForm.position"
                class="full-width"
                maxlength="200"
                placeholder="请输入"
                clearable
              />
            </el-form-item>
          </el-col>
        </el-row> -->
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="入职年月:"
              prop="entry_time"
            >
            <template #label>
              <el-tooltip class="item" effect="dark" content="如需修改，可到职位信息中修改就职时间" placement="top">
                <span>
                  <span>入职年月</span>
                  <i class="el-icon-info"></i>
                </span>
              </el-tooltip>
            </template>
              <el-date-picker
                disabled
                v-model="userForm.entry_time"
                class="full-width"
                value-format="yyyy-MM"
                :picker-options="pickerBeginDateBefore"
                type="month"
                placeholder="选择入职时间"
                @change="(value) => pikerStartTime(value)"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="离职年月:"
              prop="departure_time"
            >
            <template #label>
              <el-tooltip class="item" effect="dark" content="如需修改，可到职位信息中修改就职时间" placement="top">
                <span>
                  <span>离职年月</span>
                  <i class="el-icon-info"></i>
                </span>
              </el-tooltip>
            </template>
              <el-date-picker
                disabled
                v-if="!userForm.departure_time_checked"
                v-model="userForm.departure_time"
                value-format="yyyy-MM"
                :picker-options="pickerBeginDateBefore"
                class="input-width"
                type="month"
                placeholder="选择离职时间"
                @change="(value) => pikerEndTime(value)"
              />
              <el-checkbox
                disabled
                v-model="userForm.departure_time_checkeddeparture_time_checked"
                style="margin-top: -16px"
                label="至今"
                @change="endTimeNow($event, userForm)"
                >至今</el-checkbox
              >
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="工作证明文件:"
              prop="work_certificate"
              :rules="[
                {
                  required: true,
                  message: '请选择工作证明文件',
                  trigger: 'change',
                },
              ]"
            >
              <el-select
                v-model="userForm.work_certificate"
                class="full-width"
                placeholder="请选择工作证明文件"
                clearable
              >
                <el-option
                  v-for="(item, index) in workCertificateList"
                  :key="item.value"
                  :label="item.value"
                  :value="item.value"
                  :disabled="item.disabled"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="工作时长">
                <div>{{ workingHours }}</div>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item
              label="职责性质:"
              prop="duty"
              :rules="[
                { required: true, message: '请输入职责性质', trigger: 'blur' },
                { validator: $rules.validate.dutyInput,trigger: 'blur' }
              ]"
            >
              <el-input
                v-model.trim="userForm.duty"
                class="full-width"
                placeholder="简单概括您的工作内容（中文13字以内/英文40字以内）"
                clearable
              />
            </el-form-item>
          </el-col> -->
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="符合清单:"
              prop="match_listing"
              :rules="[
                { required: false, message: '请选择符合清单', trigger: 'blur' },
              ]"
            >
              <el-radio-group v-model="userForm.match_listing">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item
              label="相关工作属国际工作经验:"
              prop="international_work_experience"
              label-width="170px"
            >
              <el-radio-group v-model="userForm.international_work_experience">
                <el-radio :label="1">有</el-radio>
                <el-radio :label="0">没有</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col> -->
        </el-row>
        <el-divider class="divider"></el-divider>
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="企业人数规模:"
              prop="company_member_total"
              :rules="[
                {
                  required: false,
                  message: '请输入企业人数规模',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                disabled
                v-model.trim="userForm.company_member_total"
                oninput="value=value.replace(/[^\d]/g,'')"
                maxlength="8"
                class="full-width"
                placeholder="请输入企业人数规模"
                clearable
                @blur="userForm.company_member_total = $event.target.value"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="行政架构层级:" prop="administrative_level">
              <el-select
                disabled
                v-model="userForm.administrative_level"
                class="full-width"
                placeholder="请选择行政架构层级"
                clearable
              >
                <el-option
                  v-for="(item, index) in hierarchyList"
                  :key="index"
                  :label="item.tit"
                  :value="item.idx"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="下属管理人数:" prop="company_manage_total">
              <el-input
                disabled
                v-model.trim="userForm.company_manage_total"
                oninput="value=value.replace(/[^\d]/g,'')"
                maxlength="8"
                class="full-width"
                placeholder="请输入下属管理人数"
                clearable
                @blur="userForm.company_manage_total = $event.target.value"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="公司营业额/港元（近一年）:">
                <el-input
                    disabled
                    v-model.trim="userForm.turnover"
                    oninput="value=value.replace(/[^\d]/g,'')"
                    maxlength="14"
                    class="full-width"
                    placeholder="请输入公司营业额"
                    clearable
                    @blur="userForm.turnover = $event.target.value"
                />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="重要项目负责人:"
              prop="master_of_important_project"
              :rules="[
                {
                  required: false,
                  message: '请选择重要项目负责人',
                  trigger: 'blur',
                },
              ]"
            >
              <el-radio-group disabled v-model="userForm.master_of_important_project">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="2">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="公司业务性质/范畴/所属行业:"
              prop="business_nature"
            >
              <el-input
                disabled
                v-model.trim="userForm.business_nature"
                class="full-width"
                placeholder="请输入公司业务性质/范畴/所属行业"
                maxlength="100"
                clearable
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="职位水平类别:"
              prop="professional_level"
              :rules="[
                {
                  required: false,
                  message: '请选择职位水平类别',
                  trigger: 'change',
                },
              ]"
            >
              <el-select
                disabled
                v-model="userForm.professional_level"
                class="full-width"
                placeholder="请选择职位水平类别"
                @change="professionalChange"
                clearable
              >
                <el-option
                  v-for="(proitem, proindex) in postList"
                  :id="proindex"
                  :key="proitem.idx"
                  :label="proitem.tit"
                  :value="proitem.idx"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              v-if="userForm.professional_level === 1"
              label="高管证明文件:"
              prop="executives_certificate"
              :rules="[
                {
                  required: false,
                  message: '请选择高管证明文件',
                  trigger: 'change',
                },
              ]"
            >
              <el-select
                v-model="userForm.executives_certificate"
                class="full-width"
                placeholder="请选择高管证明文件"
                disabled
                clearable
              >
                <el-option
                  v-for="(item, index) in executivesCertificateList"
                  :key="index"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="[1,2,3].includes(userForm.professional_level)">
          <el-col :span="24">
            <div style="display: flex">
              <el-col :span="10">
                <el-form-item
                  label="高管在职开始时间:"
                  prop="top_manager_date_start"
                  :rules="[
                    {
                      required: false,
                      message: '请选择高管在职开始时间',
                      trigger: 'change',
                    },
                  ]"
                >
                  <el-date-picker
                    disabled
                    v-model="userForm.top_manager_date_start"
                    value-format="yyyy-MM"
                    :picker-options="pickerBeginDateBefore"
                    type="month"
                    placeholder="请选择高管在职开始时间"
                    @change="(value) => topManagerStartTime(value)"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="14" style="display: flex">
                <el-form-item
                  label="高管在职结束时间:"
                  prop="top_manager_date_end"
                  :rules="[
                    {
                      required: false,
                      message: '请选择高管在职结束时间',
                      trigger: 'change',
                    },
                  ]"
                >
                  <el-date-picker
                    disabled
                    v-if="!userForm.top_manager_date_checked"
                    v-model="userForm.top_manager_date_end"
                    value-format="yyyy-MM"
                    :picker-options="pickerBeginDateBefore"
                    type="month"
                    placeholder="请选择高管在职结束时间"
                    @change="(value) => topManagerEndTime(value)"
                  />
                  <el-checkbox
                    disabled
                    v-model="userForm.top_manager_date_checked"
                    style="margin-top: -16px; margin-left: 5px"
                    label="至今"
                    @change="tMendTimeNow($event, userForm)"
                    >至今</el-checkbox
                  >
                </el-form-item>
                <span class="manager-date"
                  >{{
                    userForm.top_manager_date_long
                      | dateLong(
                        userForm.top_manager_date_start,
                        userForm.top_manager_date_end,
                        userForm.top_manager_date_checked,
                        that
                      )
                  }}个月</span
                >
              </el-col>
            </div>
          </el-col>
        </el-row>
        <section v-for="(item, idx) in userForm.projects" :key="idx">
            <el-divider class="divider"></el-divider>
            <el-row>
            <el-col :span="12">
                <el-form-item label="项目名称:">
                <el-input v-model.trim="item.project_name" placeholder="请输入" clearable maxlength="20" class="full-width"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="项目日期:">
                <el-date-picker
                    v-model="item.project_time"
                    class="full-width"
                    type="daterange"
                    range-separator="至"
                    value-format="yyyy-MM-dd"
                    start-placeholder="开始月份"
                    end-placeholder="结束月份">
                </el-date-picker>
                </el-form-item>
            </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-form-item label="项目业绩/亮点:">
                        <el-input v-model.trim="item.project_highlights" type="textarea" :autosize="{ minRows: 3, maxRows: 4}"  placeholder="请输入" show-word-limit clearable maxlength="1000" style="width:97%"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <div style="text-align: right;padding-right: 20px">
                <el-button type="primary" @click="addProject" v-if="idx + 1 === userForm.projects.length">新增项目</el-button>
                <el-button @click="delProject(idx)">删除项目</el-button>
            </div>
        </section>
        <el-divider class="divider"></el-divider>
        <el-row class="block-top">
          <el-col :span="23">
            <p class="explain" @click="explainVisible = true">
              为了让客户您更好及更清晰的了解以下内容的填写，请点击下我，我将为您做个说明
            </p>
          </el-col>
        </el-row>
        <el-row class="block-top">
          <el-col :span="23">
            <el-form-item
              label="工作职责:"
              prop="wduty"
              :rules="[
                { required: false, message: '请输入工作职责', trigger: 'blur' },
              ]"
            >
              <el-input
                v-model="userForm.wduty"
                :rows="15"
                :placeholder="wduty"
                type="textarea"
                maxlength="2000"
                show-word-limit
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="block-top">
          <el-col :span="23">
            <el-form-item
              label="工作亮点:"
              prop="highlights"
              :rules="[
                { required: false, message: '请输入工作亮点', trigger: 'blur' },
              ]"
            >
              <el-input
                v-model="userForm.highlights"
                :rows="15"
                :placeholder="highlights"
                type="textarea"
                maxlength="2000"
                show-word-limit
              />
              <!-- <Tinymce
                :ids="'editHighlights' + userForm.id"
                :height="300"
                v-model="userForm.highlights"
                :placeholderHtml="highlights"
              ></Tinymce> -->
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="block-top jsfile">
          <el-col :span="23">
            <el-form-item label="简历/工作总结:" class="resumeFile">
              <UploadFile
                :file-names="fileNames"
                :multiple="false"
                :idx="0"
                :file-list="userForm.file"
                :is-show-file="true"
                file-list-name="fileName"
                file-list-url="fileUrl"
                :limit="100"
                :file-type="[]"
                :format-tip="[
                  'jpg',
                  'png',
                  'txt',
                  'doc',
                  'docx',
                  'ppt',
                  'pptx',
                  'xls',
                  'xlsx',
                  'pdf',
                ]"
                @fileList="onChangeFile"
              />
              <div class="not-con">
                <el-checkbox
                  v-model="userForm.not_need_upload_file"
                  :true-label="1"
                  :false-label="0"
                  >与上份工作的“简历/工作总结”一致</el-checkbox
                >
                <div class="resumeFile-msg">
                  ( 可上传多个文件，支持word、ppt、excel、图片等文件 )
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider></el-divider>
        <el-row>
            <el-col>
                <el-form-item label="公司是否上市：" label-width="auto" prop="is_ipo_company">
                    <el-radio-group v-model="userForm.is_ipo_company" @change="handleIpoCompanyChange">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="2">否</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-col>

            <el-col v-if="userForm.is_ipo_company === 1">
                <el-form-item
                label="你是否拥有此公司所有权（10%以上股权）："
                label-width="auto"
                prop="has_ipo_company_equity"
                >
                    <el-radio-group v-model="userForm.has_ipo_company_equity">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="2">否</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-col>

            <el-col v-if="userForm.is_ipo_company === 2">
                <el-form-item label="你是否拥有此公司所有权（50%以上股权）：" label-width="auto" prop="has_company_equity">
                    <el-radio-group v-model="userForm.has_company_equity">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="2">否</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-col>
            <template v-if="(userForm.has_ipo_company_equity === 1 || userForm.has_company_equity === 1) && userForm.is_ipo_company === 1">
              <el-col :span="12" >
                  <el-form-item label="公司全年盈利达港币500万元或以上（或等值外币）：" label-width="auto" prop="has_ipo_sales_reached">
                      <el-radio-group v-model="userForm.has_ipo_sales_reached">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="2">否</el-radio>
                      </el-radio-group>
                  </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="盈利/亏损金额：" label-width="auto" prop="amount_profit_loss">
                      <el-input-number :min="0" :controls="false" v-model="userForm.amount_profit_loss" :precision="0"></el-input-number>
                  </el-form-item>
              </el-col>
            </template>

            <template  v-if="(userForm.has_ipo_company_equity === 1 || userForm.has_company_equity === 1) && userForm.is_ipo_company === 2">
                <el-col :span="12">
                  <el-form-item label="公司全年盈利达港币500万元或以上（或等值外币）：" label-width="auto" prop="has_sales_reached">
                      <el-radio-group v-model="userForm.has_sales_reached">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="2">否</el-radio>
                      </el-radio-group>
                  </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="盈利/亏损金额：" label-width="auto" prop="amount_profit_loss">
                      <el-input-number :controls="false" v-model="userForm.amount_profit_loss" :precision="2"></el-input-number>
                  </el-form-item>
              </el-col>
            </template>


            <el-col v-if="(userForm.has_ipo_company_equity === 1 || userForm.has_company_equity === 1) && (userForm.has_ipo_sales_reached === 1 || userForm.has_sales_reached === 1)">
                <el-form-item label="请选择您的公司所在地：" label-width="auto" prop="company_location">
                   <el-select v-model="userForm.company_location" placeholder="请选择公司所在地">
                     <el-option value="内地" label="内地"></el-option>
                     <el-option value="香港" label="香港"></el-option>
                     <el-option value="澳门" label="澳门"></el-option>
                     <el-option value="境外" label="境外"></el-option>
                   </el-select>
                </el-form-item>
            </el-col>

            <el-col v-if="(userForm.has_ipo_sales_reached=== 2 || userForm.has_sales_reached=== 2) && userForm.has_ipo_company_equity === 1">
                <el-form-item label="您是否可以提供以下文件：" label-width="auto" prop="has_provide_certificate ">
                    <el-radio-group v-model="userForm.has_provide_certificate">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="2">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <div style="margin-left:20px;font-size:14px;color:#666;">
                    <div>年报（近一年）</div>
                    <div>上市证明（证券交易所/百度股市通截图等）</div>
                    <div>持股证明文件（周年申报表/工商备案的公司章程/国家企业信用信息公示报告/上市公司定期公告等）</div>
                </div>
            </el-col>

            <el-col :span="24"
                v-if="
                    userForm.company_location &&
                    ((userForm.has_ipo_company_equity === 1 || userForm.has_company_equity === 1) && (userForm.has_ipo_sales_reached === 1 || userForm.has_sales_reached === 1))">
                <el-form-item label="您是否可以提供以下所有证明文件：" label-width="auto" prop="has_provide_certificate">
                    <el-radio-group v-model="userForm.has_provide_certificate">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="2">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <ul class="file-list">
                    <li  class="file-list-item" v-for="v in fileMap[userForm.company_location]" :key="v">{{ v }}</li>
                </ul>
            </el-col>
        </el-row>

        <el-divider></el-divider>
        <el-row>
            <el-col :span="12">岗位职责</el-col>
            <el-col :span="12">
                <div style="text-align:right;padding-right:16px">
                    <el-button type="primary" @click="handleAdd">添加</el-button>
                </div>
            </el-col>
        </el-row>
        <el-row v-for="(item,index) in userForm.work_position" :key="index">
          <el-col :span="24">
            <el-form-item
                label="职位名称:"
                :prop="`work_position.${index}.position_name`"
                :rules="{
                    required:true,
                    message:'请输入',
                    trigger:'blur'
                }"
                >
              <el-input style="width:220px" v-model="item.position_name" placeholder="请输入" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
                label="职责性质:"
                :prop="`work_position.${index}.wduty`"
                :rules="{
                    required:true,
                    message:'请输入',
                    trigger:'blur'
                }"
            >
              <el-input type="textarea" style="width:480px" v-model="item.wduty" placeholder="简单概述您的工作内容" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
            label="经验领域:"
            prop="experience_scope"
            :rules="{
                required:true,
                trigger:'blur',
                validator:validateExperienceScope(item)
            }">
              <el-select
              v-model="item.experience_scope"
              placeholder="请选择"
              @change="()=>{item.experience_other = ''}"
              clearable >
                <el-option value="创新及科技" label="创新及科技"></el-option>
                <el-option value="金融" label="金融"></el-option>
                <el-option value="国际贸易(包括跨境海运及空运，以及供应链管理)" label="国际贸易(包括跨境海运及空运，以及供应链管理)"></el-option>
                <el-option value="其他（选填）" label="其他（选填）"></el-option>
              </el-select>
              &nbsp;
              <el-input
                v-if="item.experience_scope === '其他（选填）'"
                style="width:160px"
                placeholder="请输入经验领域"
                :maxlength="30"
                clearable
                v-model="item.experience_other"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
                label="开始就职年月:"
                :prop="`work_position.${index}.entry_time`"
                :rules="{
                    required:true,
                    message:'请选择',
                    trigger:'blur'
                }"
            >
                <el-date-picker
                    v-model="item.entry_time"
                    value-format="yyyy-MM"
                    :picker-options="pickerBeginDateBefore"
                    type="month"
                    placeholder="请选择"
                    clearable
                />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
                label="结束就职年月:"
                :prop="`work_position.${index}.departure_time`"
                :rules="{
                    required:true,
                    trigger:'blur',
                    validator:validateWorkNow(item)
                }"
            >
                <el-date-picker
                    v-if="!item.is_work_now"
                    v-model="item.departure_time"
                    value-format="yyyy-MM"
                    :picker-options="pickerBeginDateBefore"
                    type="month"
                    placeholder="请选择"
                    clearable
                />
                &nbsp;
                <el-checkbox
                    label="至今"
                    v-model="item.is_work_now"
                    :true-label="1"
                    :false-label="0"
                    @change="(v)=>{ handleWorkNowChange(v,item,index) }"
                    >
                </el-checkbox>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
                label="此职位是否属于国际工作经验:"
                :prop="`work_position.${index}.is_international_work_experience`"
                :rules="{
                    required:true,
                    message:'请选择',
                    trigger:'blur'
                }"
                label-width="auto">
                <el-radio-group v-model="item.is_international_work_experience">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <span>工作时长：</span>
            <span>{{ calculateDuration(item.entry_time, item.is_work_now ? "至今" : item.departure_time) }}</span>
          </el-col>
          <el-col :span="24">
            <el-form-item
                label="工作地点:"
                :prop="`work_position.${index}.work_city`"
                :rules="{
                    required:true,
                    trigger:'change',
                    validator:validateWork(item)
                }"
              >
                <CountrySelect
                    v-model="item.work_city.country"
                    placeholder="输入匹配国家及地区"
                    @input="(v)=>{handleSelect(v,item)}"
                    style="width: 220px"
                />
                &nbsp;
                <CitySelect
                    v-if="item.location_aboard === 1"
                    :country="item.work_city.country"
                    v-model="item.work_city.area"
                    style="width: 140px"
                />
                &nbsp;
                <el-input
                    v-if="item.location_aboard === 2"
                    v-model="item.work_city.foreign"
                    clearable
                    placeholder="请输入城市"
                    style="width: 140px"
                />
              </el-form-item>
          </el-col>

          <el-col :span="24" v-if="item.is_international_work_experience === 1">
            <el-form-item
                label="您可以提供以下哪种海外工作证明文件:"
                :prop="`work_position.${index}.provide_certificate_type`"
                :rules="{
                    required:true,
                    trigger:'change',
                    validator:validateRequired
                }"
                label-width="auto">
                <el-radio-group v-model="item.provide_certificate_type">
                    <div style="margin-bottom:4px"><el-radio :label="1">海外工作签证</el-radio></div>
                    <!-- <div style="margin-bottom:4px"><el-radio :label="2">护照出入境盖章记录（此类文件加分以港府审批为准）</el-radio></div> -->
                    <!-- <div style="margin-bottom:4px"><el-radio :label="3">出入境打印记录（此类文件加分以港府审批为准）</el-radio></div> -->
                    <div><el-radio :label="4">以上均不能提供（或将导致您的该段海外工作经验不被认可不予加分，请尽可能准备以上材料并修改此选项）</el-radio></div>
                </el-radio-group>
            </el-form-item>
          </el-col>

          <el-col>
            <div style="text-align:right;padding-right:18px">
                <el-button type="danger" @click="handleDelete(index)">移除</el-button>
            </div>
          </el-col>
          <el-col>
            <el-divider></el-divider>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <el-dialog
      :visible.sync="explainVisible"
      width="490px"
      :before-close="explainClose"
      top="5%"
    >
      <div class="explain-img" />
    </el-dialog>
  </div>
</template>

<script>
import CitySelect from "@/components/CitySelect/CitySelect"
import Tinymce from "@/components/Tinymce/Tinymce"
import {
  postIsExecutives,
  getCompanyList,
  checkCompanies,
  getCompanyWebsite
} from "../../../../../../../api/gifted"
import { mapState } from "vuex"
import { FILE_MAP } from "./config"
import { calculateDuration, calculateTotalDuration } from "@/utils/utils"
export default {
  name: "Work",
  components: {
    CitySelect,
    Tinymce,
  },
  filters: {
    dateLong: (long, start, end, check, that) => {
      if (check && start) {
        let td = that.$dayjs(new Date()).format("YYYY-MM-DD");
        return that.$dayjs(td).diff(start, "month");
      } else if (!check && start && end) {
        return that.$dayjs(end).diff(start, "month");
      } else {
        return 0;
      }
    },
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
    index: {
      type: Number,
      default: () => {
        return 0;
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // 只能选择当天之前的日期
      pickerBeginDateBefore: {
        disabledDate(time) {
          return time.getTime() >= Date.now() - 8.64e6;
        },
      },
      postList: [
        { tit: "半专业人士", idx: 5, isShow: true },
        { tit: "专业人士", idx: 4, isShow: true },
        { tit: "初级经理人", idx: 3, isShow: true },
        { tit: "中级经理人", idx: 2, isShow: true },
        { tit: "高级经理人", idx: 1, isShow: true },
        { tit: "其他", idx: 6, isShow: true },
      ], //职位水平
      hierarchyList: [
        { tit: "组织架构最顶级", idx: 1 },
        { tit: "组织架构第二层级", idx: 2 },
        { tit: "组织架构第三层级", idx: 3 },
        { tit: "组织架构第四层级", idx: 4 },
        { tit: "以上都不是", idx: 10 },
      ], //行政架构层级
      subsidiaryOrganList: [
        "有限责任公司",
        "股份有限公司",
        "跨国有限责任公司",
        "跨国股份有限公司",
        "上市公司",
        "上市跨国公司",
        "教育机构",
        "半公营企业",
        "政府机构",
        "跨国合伙企业",
        "跨国个人独资企业",
        "合伙企业",
        "个人独资企业",
        "国家/政府间组织",
        "志愿/慈善/宗教团体",
        "其他（请备注）",
      ],
      // 工作证明文件
      workCertificateList: [
        { value:'盖章并签字的推荐信 (证明效果最强)',disabled:false},
        { value:'签字的推荐信+盖章的工作证明',disabled:false},
        { value:'其他证明(退工单或合同+社保等证明) （此类文件加分以港府审批为准）',disabled:false},
        { value:'以上均不能提供（或将导致您的该段工作经验不被认可不予加分，请尽可能准备以上材料并修改此选项）',disabled:false},
        { value:'仅工作证明',disabled:false},
        { value:'签字的推荐信+社保记录/劳动合同/工牌等',disabled:true},
        { value:'仅有签字的推荐信',disabled:true},
        { value:'仅有社保记录/劳动合同/工牌等',disabled:true},
        { value:'仅提供声明书解释',disabled:true},
      ],
      // 高管证明文件
      executivesCertificateList: [
        "盖章且签字的组织架构图和审计报告",
        // "仅签字的组织架构图",
        // "其他高级管理证明文件",
      ],
      wduty: `示例: 在职期间负责的核心工作职责,建议不要超过3点。如果就职过多个职位，请分段描述;
            例子:
            模式1、在司担任某某岗位，任职期间，主要负责xxxxx工作；在此期间，组建了xxxx，实现了xxxx；
            模式2、在担任xxx岗位期间，主要负责xxx工作，具体的工作职责包括如下；
            2.1）xxxxxxx；
            2.2）xxxxxxx；
            2.3）xxxxxxx；`, //  ps: 注意修改这个文案时把提交校验的判断字符串一起修改
      highlights: `示例: 打破常规，超越同行或其他同事的优秀业绩；或有开创性且突破公司期望值的杰出贡献，且给公司带来价值和收益。
            请举例2-3个工作亮点：
            a. 是什么：某项目背景或工作目标或难点
            b. 为什么：为什么会出现这个项目工作／难点，旨在体现分析过程，为您后面提出解决方案做铺垫
            c. 怎么处理：您是怎么把这件事情做好的？用了什么技术/方法？有何突出/创新之处？
            d. 有什么价值：为公司、客户或您本身带来了哪些价值？客户或您本身带来了哪些价值？
            最好用实际数据证明，比如销售业绩增加了多少，或者利润增加了多少，客户群体增加了多少等。`, //  ps: 注意修改这个文案时把提交校验的判断字符串一起修改
      explainVisible: false, // 说明
      fileNames: "",
      rules: {
        is_ipo_company:[
            {
                required:true,
                message:'请选择',
                trigger:'change',
                validator:(rule,value,callback)=>{
                    if(!value){
                        callback(new Error('请选择'))
                    } else {
                        callback()
                    }
                }
            }
        ],
        has_company_equity:[
            {
                required:true,
                message:'请选择',
                trigger:'change',
                validator:(rule,value,callback)=>{
                    if(!value){
                        callback(new Error('请选择'))
                    } else {
                        callback()
                    }
                }
            }
        ],
        has_ipo_sales_reached:[
            {
                required:true,
                message:'请选择',
                trigger:'change',
                validator:(rule,value,callback)=>{
                    if(!value){
                        callback(new Error('请选择'))
                    } else {
                        callback()
                    }
                }
            }
        ],
        amount_profit_loss: [
          {
                required:true,
                message:'请输入',
                trigger:'change',
                validator:(rule,value,callback)=>{
                    if(!value){
                        callback(new Error('请输入'))
                    } else {
                        callback()
                    }
                }
            }
        ],
        has_sales_reached:[
            {
                required:true,
                message:'请选择',
                trigger:'change',
                validator:(rule,value,callback)=>{
                    if(!value){
                        callback(new Error('请选择'))
                    } else {
                        callback()
                    }
                }
            }
        ],
        has_ipo_company_equity:[
            {
                required:true,
                message:'请选择',
                trigger:'change',
                validator:(rule,value,callback)=>{
                    if(!value){
                        callback(new Error('请选择'))
                    } else {
                        callback()
                    }
                }
            }
        ],
        has_provide_certificate:[
            {
                required:true,
                message:'请选择',
                trigger:'change',
                validator:(rule,value,callback)=>{
                    if(!value){
                        callback(new Error('请选择'))
                    } else {
                        callback()
                    }
                }
            }
        ],
        company_location:[
            {
                required:true,
                message:'请选择',
                trigger:'change',
                validator:(rule,value,callback)=>{
                    if(!value){
                        callback(new Error('请选择'))
                    } else {
                        callback()
                    }
                }
            }
        ],
        has_share_holding:[
            {
                required:true,
                message:'请选择',
                trigger:'change',
                validator:(rule,value,callback)=>{
                    if(!value){
                        callback(new Error('请选择'))
                    } else {
                        callback()
                    }
                }
            }
        ],
      },
      isFamous: false,
      isSecondFamous: false,
      isLoading: false,
      tickDisable: false, // 是否禁用勾选名企
      that: this,
      userForm:{
        work_position:[],
        is_ipo_company:''
      },
      fileMap:FILE_MAP,
      calculateDuration,
      workingHours: '--'
    };
  },
  watch: {
    'userForm.work_position': {
        handler(val) {
            console.log('watch', val)
            if(val.length) {
                const periods = []
                val.forEach(v => {
                    periods.push({
                        start: v.entry_time,
                        end: v.is_work_now ? "至今" : v.departure_time,
                    })
                })
                this.workingHours = calculateTotalDuration(periods)
                console.log('workTimes---', this.workingHours);
            }
        },
        deep: true,
        immediate: true
    }
  },
  created() {
    this.userForm = this.data.works[this.index]
    this.isFamous = this.userForm.enterprises_type === 1
    this.isSecondFamous = this.userForm.enterprises_type === 2
    this.userForm.executives_certificate = this.userForm.executives_certificate ? "盖章且签字的组织架构图和审计报告" : ""
    this.tickDisable = this.data.works[this.index].is_edit === 1 ? false : true
    if(!this.userForm.projects || !this.userForm.projects.length) {
        this.userForm.projects.push({ project_name: '', project_time: [], project_highlights: '' })
    }
  },
  methods: {
    // 禁止入职年月乱选
    pikerStartTime(e, idx) {
      let entry_time = e;
      let departure_time = this.userForm.departure_time;
      if (departure_time && !this.userForm.departure_time_checked) {
        let result =
          new Date(departure_time).getTime() > new Date(entry_time).getTime();
        if (!result) {
          this.userForm.entry_time = "";
          this.$message.error("入职年月不得在离职年月之后");
        }
      }
    },
    // 禁止入职年月乱选
    pikerEndTime(e, idx) {
      let entry_time = this.userForm.entry_time;
      let departure_time = e;
      if (entry_time && !this.userForm.departure_time_checked) {
        let result =
          new Date(entry_time).getTime() < new Date(departure_time).getTime();
        if (!result) {
          this.userForm.departure_time = "";
          this.$message.error("离职年月不得在入职年月之前");
        }
      }
    },
    // 至今选择
    endTimeNow(e, item) {
      if (e) {
        item.departure_time = "";
      } else {
        if (item.departure_time === "至今") {
          item.departure_time = "";
        }
      }
    },
    // 企业名称搜索建议
    querySearchAsync(queryString, cb) {
      getCompanyList({ keyword: queryString.trim() }).then((res) => {
        if (res.code === 200) {
          const searchResult = res.data.map((item) => {
            return { value: item.name };
          });
          cb(searchResult);
        }
      });
    },
    // 禁止高管年月乱选
    topManagerStartTime(e) {
      let start_time = e;
      let end_time = this.userForm.top_manager_date_end;
      if (end_time && !this.userForm.top_manager_date_checked) {
        let result =
          new Date(end_time).getTime() > new Date(start_time).getTime();
        if (!result) {
          this.userForm.top_manager_date_start = "";
          this.$message.error("高管在职年月不得在离职年月之后");
        }
      }
    },
    // 禁止入职年月乱选
    topManagerEndTime(e) {
      let start_time = this.userForm.top_manager_date_start;
      let end_time = e;
      if (start_time && !this.userForm.top_manager_date_checked) {
        let result =
          new Date(start_time).getTime() < new Date(end_time).getTime();
        if (!result) {
          this.userForm.top_manager_date_end = "";
          this.$message.error("离职年月不得在入职年月之前");
        }
      }
    },
    // 高管至今选择
    tMendTimeNow(e, item) {
      if (e) {
        item.top_manager_date_end = "";
      } else {
        if (item.top_manager_date_end === "至今") {
          item.top_manager_date_end = "";
        }
      }
    },
    // 更改工作地点
    changeLocationAboard(e, item) {
      if (e === 0) {
        item.location.foreign = "";
      } else {
        item.location.area = [];
      }
    },
    // 上传列表变化
    onChangeFile(list) {
      this.userForm.file = list;
    },

    handleWorkNowChange(v,item,index){
        if(v===1){
            item.departure_time = ''
        }
        this.$refs.userForm.validateField(`work_position.${index}.departure_time`)
    },
    // 是否是高管
    // async isExecutives(item) {
    //   if (
    //     item.company_member_total !== "" &&
    //     item.company_manage_total !== "" &&
    //     item.turnover !== ""
    //   ) {
    //     let param = {
    //       company_member_total: Number(item.company_member_total),
    //       turnover: Number(item.turnover),
    //       company_manage_total: Number(item.company_manage_total),
    //     };
    //      return postIsExecutives(param).then((res) => {
    //       if (res.code === 200) {
    //         if (res.data === 1) {
    //           item.is_executives = res.data;
    //           item.professional_level = 1;
    //           item.executives_certificate = "盖章且签字的组织架构图和审计报告";
    //         } else if (res.data === 0) {
    //           item.is_executives = res.data;
    //           item.professional_level = item.professional_level === 1?"":item.professional_level;
    //           item.executives_certificate = "";
    //         }
    //       }
    //     });
    //   }
    // },
    explainClose() {
      this.explainVisible = false;
    },

    handleAdd(){
        if(this.userForm.work_position.length>=5){
            return this.$message({
                type:'warning',
                message:'最多添加5个职位'
            })
        }
        this.userForm.work_position.push({
            id:0,
            wduty:'',
            position_name:'',
            experience_scope:'',
            experience_other:'',
            entry_time:'',
            departure_time:'',
            work_city:{
                area:[],
                country:'',
                details:'',
                foreign:''
            },
            is_work_now:0,
            location_aboard:0,
            is_international_work_experience:'',
            provide_certificate_type:''

        })
    },

    handleDelete(index){
        this.userForm.work_position.splice(index,1)
        this.$refs.userForm.validate()
    },

    validateWork(item){
        return function (rule,value,callback){
            if(!value.country){
                callback(new Error('请选择国家'))
            }else if(item.location_aboard === 1 && value.area.length === 0){
                callback(new Error('请选择城市'))
            }else if(item.location_aboard === 2 && !value.foreign){
                callback(new Error('请输入城市'))
            } else {
                callback()
            }
        }
    },


    validateExperienceScope(item){
        return function (rule,value,callback){
            if(item.experience_scope === '其他（选填）' && !item.experience_other){
                callback(new Error('请输入经验领域'))
            } else {
                callback()
            }
        }
    },

    validateWorkNow(item){
        return function (rule,value,callback){
            if(!item.is_work_now && !value){
                callback(new Error('请选择结束就职年月'))
            } else {
                callback()
            }
        }
    },

    validateRequired(rule,value,callback){
        if(!value){
            callback(new Error('请选择'))
        }else{
            callback()
        }
    },

    // 校验
    async save() {
        let that = this;
        return new Promise(function (resolve, reject) {
            that.$refs.userForm.validate((valid) => {
            if (valid) {
                const { company_member_total, company_manage_total, turnover } = that.userForm

                if(company_member_total === 0 || company_member_total) {
                    that.userForm.company_member_total = Number(company_member_total);
                } else {
                    that.userForm.company_member_total = null
                }


                if(company_manage_total === 0 || company_manage_total) {
                    that.userForm.company_manage_total = Number(company_manage_total);
                } else {
                    that.userForm.company_manage_total = null
                }


                if(turnover === 0 || turnover) {
                    that.userForm.turnover = Number(turnover);
                } else {
                    that.userForm.turnover = null
                }

                if(that.userForm.industry !== '其他（请备注）') {
                    that.userForm.business_remark = ''
                }

                if(that.userForm.is_ipo_company === 1){
                    that.userForm.has_sales_reached = 0
                }
                if(that.userForm.is_ipo_company === 2){
                    that.userForm.has_ipo_sales_reached = 0
                }
                // 公司数据处理 2 or 3 为否把其他数据都恢复初始化
                if(that.userForm.has_ipo_company_equity === 2 || that.userForm.has_company_equity === 2){
                    that.userForm.has_ipo_sales_reached = 0
                    that.userForm.company_location = ''
                    that.userForm.has_provide_certificate = 0
                    //that.userForm.has_share_holding = 0
                } else {
                    if(that.userForm.has_ipo_sales_reached === 1){ // 500万是
                        //that.userForm.has_share_holding = 0
                    }
                    if(that.userForm.has_ipo_sales_reached === 2 || that.userForm.has_sales_reached === 2 ){ // 500万否
                        that.userForm.company_location = ''
                        //that.userForm.has_provide_certificate = 0
                    }
                }
                resolve(valid);
            } else {
                reject();
            }
            });
        });
    },
    // 输入匹配
    handleSelect(v,item) {
      if (v.indexOf("中国") !== -1) {
        item.location_aboard = 1
      } else {
        item.location_aboard = 2
      }
      item.work_city.area = []
      item.work_city.foreign = ""
    },
    // 校验选中的企业是否为名企
    async checkEnterprise(name) {
        this.getCompanyDate(name)
      try {
        this.isLoading = true;
        const res = await checkCompanies({ name });
        if (res.code === 200) {
          this.userForm.is_famous_enterprise = res.data;
          this.isFamous = res.data === 1;
          this.isSecondFamous = res.data === 2;
          this.userForm.enterprises_type = res.data;
          this.tickDisable = res.data === 1 ? true : false;
          this.userForm.is_edit = res.data === 1 ? 0 : 1;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    // 获取公司信息
    async getCompanyDate(company_name) {
        const { data: { intro, website_link } } = await getCompanyWebsite({ company_name })
        this.userForm.company_introduce = intro
        this.userForm.company_website = website_link
    },

    // 新增项目
    addProject() {
        this.userForm.projects.push({ project_name: '', project_time: [], project_highlights: '' })
    },

    // 删除项目
    delProject(i) {
        this.$confirm("是否删除当前项目经历?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
                customClass: 'confirm'
            }).then(() => {
                this.userForm.projects.splice(i, 1)
            })
    },

    professionalChange(e) {
        if (e === 1) {
            this.userForm.is_executives = 1
            this.userForm.executives_certificate = "盖章且签字的组织架构图和审计报告";
        } else {
            this.userForm.is_executives = 0
            this.userForm.executives_certificate = "";
        }
    },

    // 勾选时更改需要传的字段
    triggerTick(val) {
      if (val) {
        this.isSecondFamous = false;
      }
      this.userForm.is_famous_enterprise = val ? 1 : 0;
      this.userForm.enterprises_type = val ? 1 : 0;
      this.tickDisable = false; // 只要是人为手动勾选的 都可以进行反选
    },
    triggerTickSec(val) {
      if (val) {
        this.isFamous = false;
      }
      this.userForm.is_famous_enterprise = val ? 2 : 0;
      this.userForm.enterprises_type = val ? 2 : 0;
      this.tickDisable = false; // 只要是人为手动勾选的 都可以进行反选
    },


    // 公司是否上市
    handleIpoCompanyChange(v){
        if(v===1){
            this.userForm.has_company_equity = 0
        }else{
            this.userForm.has_ipo_company_equity = 0
        }
        this.userForm.has_ipo_sales_reached = 0
        this.userForm.company_location = ''
        //this.userForm.has_share_holding = 0
        this.userForm.has_provide_certificate = 0
    }
  },
};
</script>

<style lang="less" scoped>
.sigh-img {
  position: absolute;
}

.city-inline {
  display: inline-block;
  width: 150px;
}

.input-width {
  width: 232px;
  margin-right: 10px;
}
.full-width {
  width: 300px;
}
.work-box {
  border-bottom: 1px solid #dcdfe6;
  padding-bottom: 14px;
  padding-top: 15px;
  .workr-heard {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 12px 16px 10px 16px;
  }
}
.work-box:first-child {
  padding-top: 0;
}
.flex-center {
  display: flex;
  align-items: flex-start;
}

.margin-zero {
  margin-bottom: 0;
}

.add-people {
  width: 928px;
  height: 35px;
  line-height: 35px;
  border: 1px solid #dcdfe6;
  color: #409eff;
  font-size: 14px;
  text-align: center;
  margin: 16px auto;
  cursor: pointer;
}
.experience-box {
  padding: 22px 0 0 16px;
  font-size: 14px;
  line-height: 20px;
  color: #606266;

  .experience-title {
    color: #303133;
    margin-bottom: 10px;
    position: relative;
    padding-left: 12px;

    &::before {
      content: "*";
      position: absolute;
      left: 0;
      top: 2px;
      color: red;
    }
  }
  .experience-des {
    color: #e6a23c;
    margin-bottom: 18px;

    a {
      color: #409eff;
      margin-left: 10px;
    }
  }

  .experience-radio {
    margin-left: 0;

    .el-radio {
      display: block;
      margin-bottom: 12px;
    }
  }

  .experience-radio-four {
    .el-radio {
      width: 22%;
      margin-bottom: 12px;
    }
  }
}

.resumeFile {
  position: relative;
  display: flex;
  .resumeFile-msg {
    color: red;
    display: inline-block;
    width: 400px;
    margin-left: 20px;
  }
}

.explain {
  padding-left: 70px;
  padding-bottom: 5px;
  font-size: 14px;
  color: #02a7f0;
  cursor: pointer;
}

.explain-img {
  width: 100%;
  height: 690px;
  background: url("https://upload-cdn.galaxy-immi.com/public/img/crm/explain.jpg?x-oss-process=image/resize,w_450,m_lfit")
    no-repeat center;
}
.jsfile {
  /deep/ .el-form-item__content {
    margin-left: 0px !important;
  }
}
.not-con {
  position: absolute;
  left: 90px;
  top: 0px;
  display: flex;
}
.interval {
  height: 100px;
}
.manager-date {
  margin-left: 10px;
  color: #666;
  padding-top: 5px;
  font-size: 14px;
}
.divider {
  background-color: #eff2fa;
}
.tick-famous-enterprise {
  margin-left: 4px;
  margin-right: 2px;
  /deep/ .el-checkbox__label {
    padding-left: 3px;
  }
}

.file-list{
    font-size:14px;
    padding:12px 12px 12px 30px;
    color:#333;
    border:1px solid #919090;
    width:fit-content;
    margin-left:20px;
    list-style-type:disc;

}
.file-list-item{

}
</style>
