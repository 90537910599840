import { render, staticRenderFns } from "./EditEducation.vue?vue&type=template&id=a9a21328&scoped=true&"
import script from "./EditEducation.vue?vue&type=script&lang=js&"
export * from "./EditEducation.vue?vue&type=script&lang=js&"
import style0 from "./EditEducation.vue?vue&type=style&index=0&id=a9a21328&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a9a21328",
  null
  
)

export default component.exports