<template>
    <div>
        <Container padding="0" id="works-top">
            <span id="works-top-none"></span>
            <div slot="title" class="title-container">
                <div>主申请人工作经历</div>
                <div class="work-time" v-if="info.work_time">
                    <span class="work-time-item" v-for="item in workTimeMap" :key="item.key">{{ item.label }}{{ info.work_time[item.key] || '-' }}</span>
                </div>
            </div>
            <div slot="handle">
                <div class="handle-btn">
                    <el-button @click="add">新增</el-button>
                </div>
            </div>
            <Work ref="work" :data="info"/>
        </Container>
    </div>
</template>

<script>
import Work from './C/Work/Work.vue'
export default {
    components: {
        Work
    },
    props: {
        info: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {
            workTimeMap: [
                {
                    key: 'all',
                    label: '总工作时长：'
                },
                {
                    key: 'oversea',
                    label: '海外工作时长：'
                },
                {
                    key: 'famous',
                    label: '名企工作时长：'
                },
                {
                    key: 'special',
                    label: '特殊领域时长：'
                }
            ]
        }
    },
    mounted() {
    },
    methods: {
        add() {
            this.$refs.work.addWork();
            // this.info.works.push({
            //     //工作经历
            //     id: '',
            //     company_name: "", //雇主名称
            //     duty: "", //职责性质
            //     entry_time: "", //入职时间
            //     departure_time: "", //离职时间
            //     industry: "", //雇主所属机构性质
            //     location: {
            //         //工作地点
            //         area: [],
            //         foreign: "",
            //         country: "",
            //     },
            //     location_aboard: "", //工作地点国内国外  0-国内 1-国外
            //     professional_level: "", //职位水平(1.高层管理岗2.中层管理岗3.低层管理岗4.专业人士5.半专业人士6.其他)
            //     professionalOther: "",
            //     work_certificate: "", //工作证明文件
            //     is_executives: 0,
            //     is_synthetic: 1, // 是否合成 1合成 2不合成
            //     company_member_total: '', //企业人数规模
            //     administrative_level: '', //行政架构层级
            //     company_manage_total: '', //下属管理人数
            //     top_manager_date_checked: false, // 高管至今
            //     top_manager_date_start: "", // 高管开始时间
            //     top_manager_date_end: "", // 高管结束时间
            //     departure_time_checked: false, // 是否选中至今
            //     executives_certificate: "", //高管证明文件
            //     position: "", //职位
            //     remark: "", //备注
            //     wduty: null,
            //     highlights: null,
            //     file: [],
            //     key: Date.now(),
            //     not_need_upload_file: 0, // 是否与上份工作的“简历/工作总结”一致
            //     isEdit: true,
            //     master_of_important_project: null,
            //     match_listing: 0,
            // });
            // this.$store.dispatch('previewPdfGifted/setStateKeyData', { key: 'works', data: JSON.parse(JSON.stringify(this.info)) })
            // this.anchorPoint()
        },
        // 新增锚点到新增相应的位置
        // anchorPoint() {
        //     this.$nextTick(() => {
        //         const pageId = document.querySelector('#works' + (this.info.works.length - 1))
        //         window.scrollTo({
        //             top: pageId.offsetTop,
        //             behavior: 'smooth'
        //         })
        //     })
        // }
    }
}
</script>

<style lang="less" scoped>
.container {
    position: relative;
    .title-btn {
        position: absolute;
        top: 4px;
        right: 15px;
    }
}

.title-container {
    display: flex;
    align-items: center;

    .work-time {
        margin-left: 40px;

        .work-time-item {
            margin-right: 12px;
        }
    }
}
</style>
